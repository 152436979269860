%base-modal-panel {
	position: relative;

	width: 100%;
	max-width: 30rem;

	margin: 0 auto;
	padding: 16px;

	background: white;

	transform: translate3d(0, 20px, 0);
	transition: all var(--timing-faster) ease;

	opacity: 0;

	> .close {
		position: absolute;
		right: 0.5rem;
		top: 0;
		right: 0;

		cursor: pointer;
	}
}

.#{$component-prefix}modal {
	position: fixed;
	overflow-y: auto;
	z-index: 1;

	display: flex;
	flex-direction: column;

	top: 0;
	left: 0;

	width: 100vw;
	height: 100vh;

	margin: auto;

	background: hsla(0, 0%, 0%, 0.56);

	pointer-events: none;
	visibility: hidden;
	opacity: 0;

	transition: all var(--timing-faster) ease;

	&:before,
	&:after {
		content: '';

		display: block;

		min-height: 2rem;
		height: 1.5rem;

		-webkit-box-flex: 1;
		box-flex: 1;
		-webkit-flex-grow: 1;
		flex-grow: 1;

	}

	> .#{$component-prefix}modal-panel {
		@extend %base-modal-panel;
	}

	&.-active {
		pointer-events: all;
		visibility: visible;
		opacity: 1;

		.#{$component-prefix}modal-panel {
			transform: translate3d(0, 0, 0);
			opacity: 1;
		}
	}
}
