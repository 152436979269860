$components-colors: (
	'primary',
	'positive',
	'negative'
);

.#{$component-prefix}link {
	position: relative;

	color: rgb(30, 175, 233);

	text-decoration: none;

	cursor: pointer;
	outline: none;
	transition: all 160ms ease-in-out;

	svg, path {
		transition: fill 160ms ease-in-out;
		fill: var(--color-gray-800);
	}

	&:before, &:after {
		pointer-events: none;
		backface-visibility: hidden;
	}

	&:hover, &:focus, &.-active {
		color: rgb(21, 160, 214);

		svg, path {
			fill: var(--color-primary-600);
		}
	}

	&.-fancy {
		border-radius: 3px;

		&:after {
			position: absolute;
			opacity: 0;

			content: '';

			left: 0;
			bottom: 0;

			display: block;

			width: 100%;
			height: 1px;

			background-color: var(--color-primary-400);
			transition: all 160ms ease-in-out;
		}

		&:hover, &:focus, &.-active {
			background-color: var(--color-primary-100);

			&:after {
				opacity: 1;
			}
		}
	}

	&:not(.-fancy):focus {
		text-decoration: underline;
	}

	@each $key in $components-colors {
		&.-#{$key} {
			color: var(--color-#{$key}-500);

			svg, path {
				fill: var(--color-#{$key}-500);
			}

			&:hover, &:focus, &.-active {
				color: var(--color-#{$key}-600);

				svg, path {
					fill: var(--color-#{$key}-600);
				}
			}
		}
	};
}
