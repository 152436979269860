%panel-base {
	position: relative;

	width: 100%;
	max-width: 640px;

	margin: 0 auto;
	padding: 24px;

	background: white;
    border-radius: 4px;
}
