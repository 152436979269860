@import './theme.scss';
@import './reset.scss';

@import './atomic/all.scss';
@import './component/all.scss';
@import './layout/all.scss';

[x-cloak] {
	display: none !important;
}
